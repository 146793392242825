import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
// import * as faceapi from 'face-api.js';
// import ImageCapture from 'react-image-data-capture';

import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from "@tensorflow-models/coco-ssd";


import { validate_role } from '../../validations';
// import { get_users } from '../../actions/user-actions';
// import { add_log } from '../../actions/log-actions';
// import { add_footage } from '../../actions/footage-actions';
// import { add_alert } from '../../actions/alert-actions';


import VideoDetector from "./detection/VideoDetector";


let token_string = localStorage.getItem('token');



function Surveillance(props) {


    const [source, setSource] = useState("webcam");
  const [model, setModel] = useState(null);


    let navigate = useNavigate()
    let params = useParams()
    const id = params.id ? params.id : '';

    // console.log('id', id)

    const [personFound, setPersonFound] = useState(null);
   

    // eslint-disable-next-line
    let user_profile;
    const permitted_role = 'admin';
    const check_permissions = validate_role(permitted_role);
    if (check_permissions.permitted === false) {
        let login_link = '/login';
        if (permitted_role === 'admin') login_link = 'access-forbidden'
        navigate(login_link);
    } else {
        user_profile = check_permissions.user_object;
    }

    // eslint-disable-next-line
    const [error_message, set_error_message] = useState('');
    // eslint-disable-next-line
    const [success_message, set_success_message] = useState('');
    const [page_loaded, set_page_loaded] = useState(false);

    const productList = useSelector((state) => state.productList);
    const { loading, error } = productList;

    
    

    const load_page_data = async () => {
        return;
    }

    const handleChangeSource = newSource => {
        setSource(newSource);
      };


    // eslint-disable-next-line
    const dispatch = useDispatch();
    useEffect(() => {

        // const MODEL_URL = 'http://127.0.0.1:3000' + '/models/ssd_mobilenet_v2/model.json';

        // Promise.all([
        // await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),  
        
        const loadModel = async () => {
            const loadedModel = await cocoSsd.load( { base: "mobilenet_v2" } )
            console.log('loaded', loadedModel)
            setModel(loadedModel)
        }
        loadModel()


        if (page_loaded === false) {
            load_page_data();
            set_page_loaded(true);
        }
        // eslint-disable-next-line
    }, []);





    return (
        <>

            <div class="breadcrumbs">
                <div class="page-header d-flex align-items-center general-page" style={{ backgroundImage: 'logis/assets/img/page-header.jpg', }}>
                    <div class="container position-relative">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-6 text-center">
                                <h2>Video Surveillance</h2>

                                {/* <div style={{ textAlign: 'center', padding: '10px' }}>
                                {
                                    captureVideo && modelsLoaded ?
                                        <button onClick={closeWebcam} style={{ cursor: 'pointer', backgroundColor: 'red', color: 'white', padding: '15px', fontSize: '25px', border: 'none', borderRadius: '10px' }}>
                                            Stop monitoring movements
                                        </button>
                                        :
                                        <button onClick={startVideo} style={{ cursor: 'pointer', backgroundColor: 'green', color: 'white', padding: '15px', fontSize: '25px', border: 'none', borderRadius: '10px' }}>
                                            Start monitoring movements
                                        </button>
                                }
                            </div> */}




                            </div>
                        </div>
                    </div>
                </div>
                <nav>
                    <div class="container">
                        {error_message !== '' ? (
                            <div class="alert alert-danger" role="alert">
                                {error_message}
                            </div>
                        ) : ('')}
                        {success_message !== '' ? (
                            <div class="alert alert-success" role="alert">
                                {success_message}
                            </div>
                        ) : ('')}
                    </div>
                </nav>
            </div>

            <div class="container" data-aos="fade-up">




                {loading ? ( // so that only products will be loading at this time 
                    <div>Loading ... </div>
                ) : error ? (
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger" role="alert">
                                An error occured loading page
                            </div>
                        </div>
                    </div>
                ) : (
                    <div class="row">

                        <div class="col-md-12 camera-area">
                       

                        {model === null && (<>loading ...</>)}
          {model !== null && (<VideoDetector objectDetector={model} road={id} />)}



                        </div>

                        <div class="col-md-6 student-area m-auto">

                        </div>
                    </div>
                )}

            </div>
        </>
    );
}
export default Surveillance;
