import React from "react"

const Error404 = () => {
  return (
    <>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2></h2>
            <ol>
              <li></li>
              <li></li>
            </ol>
          </div>

        </div>
      </section>

      <main id="main m-5">
        Error 404
      </main>
    </>
  );
};

export default Error404;
