import React, { useEffect, useState } from 'react';
import { login } from '../../actions/user-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Loading from "../../utils/components/Loading"
import { get_audits } from '../../actions/audit-actions';
import { get_display_date } from '../../config';


const Motivations = () => {
    const userSignin = useSelector(state => state.userSignin);
    const { loading, userInfo, error } = userSignin;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    // const redirect = this.props.location.search ? this.props.location.search.split("=")[1] : 'dashboard';
    const redirect = 'dashboard'

    const [error_message, set_error_message] = useState('');
    const [success_message, set_success_message] = useState('');
    // eslint-disable-next-line
    const [action_loading, set_action_loading] = useState(false);
    const [page_loaded, set_page_loaded] = useState(false);

    const [stage, set_stage] = useState('2');

    const [email, set_email] = useState('');
    const [password, set_password] = useState('');

    const [fines, set_fines] = useState([]);

    const load_page_data = async () => {
        let get_admins;
    
        try {
          get_admins = await get_audits('id');
        //   console.log(get_admins)
          if (get_admins.success) {
            const { data } = get_admins;
            
            const types = [
                "Financial Gain",
                "Espionage",
                "Hacktivism",
                "Sabotage and Disruption",
                "Personal Vendettas",
                "Ransom",
                "Political Discord",
                "Competitive Advantage/Stealing Trade Secrets",
                "Thrill-Seeking and Notoriety",
                "Unknown",
            ]


            let _fines = []

            let t_count = 0
                let t_resolved = 0
                let t_unresolved = 0

            for (let j = 0; j < types.length; j++) {
                let t = types[j]
                let count = 0
                let resolved = 0
                let unresolved = 0

                for (let i = 0; i < data.length; i++) {
                    const f = data[i]
                    if (f.authenticated) {
                         if (f.motivation === t) {
                            count++
                            t_count++

                            if (f.status === 'Resolved') {
                                resolved++
                                t_resolved++
                            } else {
                                unresolved++
                                t_unresolved++
                            }
                         }
                    }
                }

                _fines.push({
                    t,
                    count,
                    resolved,
                    unresolved
                })
            }

            // _fines.push({
            //     t: 'Total',
            //     count: t_count,
            //     resolved: t_resolved,
            //     unresolved: t_unresolved
            // })
            
            set_fines(_fines);
          }
    
        } catch (error) {
          set_error_message(`An error occured fetching imposed traffic violations: ${error.message} `);
        }
    
        return;
      }

    useEffect(() => {
        if (page_loaded === false) {
          load_page_data();
          set_page_loaded(true);
        }
        // eslint-disable-next-line
      }, []);

    const signin_user = async (e) => {
        e.preventDefault();

        set_success_message('');
        set_error_message('');
        set_action_loading(true);

        try {
            await dispatch(login(email, password, redirect));
            // const response = await login(email, password, redirect, props);
            // // console.log('component response', response)
            // if (response.success) {

            //   set_success_message(response.message)
            //   setForm(3);

            // } else {
            //   set_error_message(response.message);
            // }
        } catch (error) {
            console.log(`${error.message}`);
            set_error_message('An error occured signing up your account');
        }

        set_action_loading(false);


        return;
    }


    


    return (
        <>
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                        <h2></h2>
                        <ol>
                            <li></li>
                            <li></li>
                        </ol>
                    </div>

                </div>
            </section>

            <main id="main mt-5">

                <div class="container">
                    <div class="row">
                        <div class="offset-md-12 col-md-12">
                        <>
                                                    <h3 class="mt-5">Motivations</h3>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-striped">
                                                            <tr>
                                                                <th>Type</th>
                                                                {/* <th>Unresolved Issues</th> */}
                                                                {/* <th>Resolved Issues</th> */}
                                                                <th>Total Issues</th>
                                                               
                                                            </tr>

                                                            {fines.length > 0 ? (
                                                                fines.map((f) => (
                                                                    <tr>
                                                                        <td>{f.t}</td>
                                                                        {/* <td>{f.unresolved}</td> */}
                                                                        {/* <td>{f.unresolved}</td> */}
                                                                        <td>{f.count}</td>
                                                                        
                                                                    </tr>
                                                                ))

                                                            ) : (<></>)}


                                                        </table>
                                                    </div>
                                                </>
                                                </div>
                    </div>
                </div>





            </main>
        </>
    );
};

export default Motivations;
