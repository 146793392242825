import React, { useEffect, useState } from 'react';
import { login } from '../../actions/user-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Loading from "../../utils/components/Loading"
import { add_violation, get_violations, update_violation } from '../../actions/violation-actions';
import { get_display_date } from '../../config';


const SearchVehicle = () => {
    const userSignin = useSelector(state => state.userSignin);
    const { loading, userInfo, error } = userSignin;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    // const redirect = this.props.location.search ? this.props.location.search.split("=")[1] : 'dashboard';
    const redirect = 'dashboard'

    const [error_message, set_error_message] = useState('');
    const [success_message, set_success_message] = useState('');
    // eslint-disable-next-line
    const [action_loading, set_action_loading] = useState(false);
    const [page_loaded, set_page_loaded] = useState(false)

    const [stage, set_stage] = useState('1');

    const [vehicle, set_vehicle] = useState('');
    const [password, set_password] = useState('');

    const [fines, set_fines] = useState();

    const load_page_data = async () => {
        let get_admins;
    
        try {
          get_admins = await get_violations('id');
        //   console.log(get_admins)
          if (get_admins.success) {
            const { data } = get_admins;
            set_fines(data);
          }
    
        } catch (error) {
          set_error_message(`An error occured fetching imposed traffic violations: ${error.message} `);
        }
    
        return;
      }


      useEffect(() => {
        if (page_loaded === false) {
          load_page_data();
          set_page_loaded(true);
        }
        // eslint-disable-next-line
      }, []);

    const signin_user = async (e) => {
        e.preventDefault();

        set_success_message('');
        set_error_message('');
        set_action_loading(true);

        try {
            let _fines = []

            for (let i =0; i < fines.length; i++) {
                if (fines[i].vehicle === vehicle) {
                    if (fines[i].status !== 'Paid') {
                        _fines.push(fines[i])
                    }
                }
            }

            set_fines(_fines)
            set_stage('2')
            
        } catch (error) {
            console.log(`${error.message}`);
            set_error_message('An error occured signing up your account');
        }

        set_action_loading(false);


        return;
    }


    return (
        <>
            <section class="breadcrumbs">
                <div class="container">

                    <div class="d-flex justify-content-between align-items-center">
                        <h2></h2>
                        <ol>
                            <li></li>
                            <li></li>
                        </ol>
                    </div>

                </div>
            </section>

            <main id="main mt-5">

                <div class="container">
                    <div class="row">
                        <div class="offset-md-3 col-md-6">
                            <section class="inner-page">
                                <div class="container">
                                    <>
                                        <div className="form">
                                            <form onSubmit={signin_user} >
                                                <div>
                                                    {error_message !== '' ? (
                                                        <div class="alert alert-danger" role="alert">
                                                            {error_message}
                                                        </div>
                                                    ) : ('')}
                                                    {success_message !== '' ? (
                                                        <div class="alert alert-success" role="alert">
                                                            {success_message}
                                                        </div>
                                                    ) : ('')}
                                                </div>

                                                <div>
                                                    <input
                                                        type="text"
                                                        className="input-field form-control mt-3"
                                                        placeholder="Enter vehicle registration number. Eg. AFM8853"
                                                        pattern="[A-Z]{3}[0-9]{4}"
                                                        onChange={(e) => set_vehicle(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary mt-3"
                                                    disabled={action_loading} >
                                                    Search
                                                </button>
                                            </form>

                                            {stage === '2' ? (
                                                <>
                                                    <h3 class="mt-5">Traffic light violations for vehicle {vehicle}</h3>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered table-striped">
                                                            <tr>
                                                                <th>Date &amp; time</th>
                                                                <th>Vehicle</th>
                                                                <th>Direction</th>
                                                                <th>Fine charged</th>
                                                                <th></th>
                                                            </tr>

                                                            {fines.length > 0 ? (
                                                                fines.map((f) => (
                                                                    <tr>
                                                                        <td>{get_display_date(f.createdAt)}</td>
                                                                        <td>{f.vehicle}</td>
                                                                        <td>{ f.direction ? f.direction:'Hamutyinei Road and Lower Gweru Road Junction' }</td>
                                                                        <td>${f.amount}</td>
                                                                        <td>
                                                                            <Link to={`/pay/${f._id}`} class="btn btn-success btn-sm" style={{color: 'green'}}>Pay fine</Link>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                            ) : (<>Hooray, no violations found</>)}


                                                        </table>
                                                    </div>
                                                </>
                                            ) : (<></>)}
                                        </div>
                                        <div className="links mt-5">
                                            <Link to="/"
                                            //   onClick={() => {
                                            //     setForm(2);
                                            //   }}
                                            >
                                                Back home
                                            </Link>
                                        </div>
                                    </>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>





            </main>
        </>
    );
};

export default SearchVehicle;
