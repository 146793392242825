import React, { useState, useEffect } from "react";
import { useNavigate, useParams, redirect } from "react-router-dom";
import { add_payment } from '../../actions/violation-actions';

const Paynow = (props) => {
  const params = useParams()
  // console.log(params.id)
  const navigate = useNavigate()

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);
  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');


  const user = params.user
  const _package = params.package

  const packages = {
    Gold: 15.99,
    Bronze: 10.99,
    Silver: 7.99
  }

  const load_page_data = async () => {
    let get_all_users;

    let add_data = {
      id: params.id, 
    }

    try {
      get_all_users = await add_payment(add_data);
      if (get_all_users.success === false) {
        set_error_message(get_all_users.message);
      }
      else {
        const { paynow_link } = get_all_users;

        // redirect to paynow 
        console.log(paynow_link, get_all_users)
        return window.location.replace(paynow_link)

      }
    } catch (error) {
      set_error_message(`An error occured initiating payment: ${error.message} `);
    }

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      You will be redirected in 5 seconds.
    </>
  );
};

export default Paynow;
