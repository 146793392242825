import Axios from "axios";

import { API_URL  } from "../config";
import { cos } from "@tensorflow/tfjs";
let token_string = localStorage.getItem('token');


export const add_login = async (add_data) => {
    if (token_string === null) { token_string = localStorage.getItem('token_string') }
  
    try {
      const { data } = await Axios.post(
        `${API_URL}/logins`,
        add_data,
      );
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Sorry we could not add login. Please try again',
      }
    }
  }

  export const add_payment = async (add_data) => {
    if (token_string === null) { token_string = localStorage.getItem('token_string') }
  
    try {
      const { data } = await Axios.post(
        `${API_URL}/logins/payment`, 
        add_data, 
      );
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Sorry we could not add payment. Please try again',
      }
    }
  }

  export const get_logins = async (id) => {
    if (token_string === null) { token_string = localStorage.getItem('token') }

    try {
      const { data } = await Axios.get(
        `http://127.0.0.1:4002/logins?id=${id}`, {
          headers: {
            Authorization: 'Bearer ' + token_string,
          },
        });
        console.log(data)
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Failed to retrieve login(s). Please reload page',
      }
    }
  }


  export const get_ratio = async (id) => {
    // try {
    //   const { data } = await Axios.get(
    //     `${API_URL}/logins/ratio`, {
    //     //   headers: {
    //     //     Authorization: 'Bearer ' + token_string,
    //     //   },
    //     });
    //     console.log(data)
    //   return data;
    // } catch (error) {
    //   console.log(error.message);
    //   return {
    //     success: false,
    //     message: 'Failed to retrieve login ratios. Please reload page',
    //   }
    // }
    return {
      success: true,
      message: ''
    }
  }

  export const update_login = async (id, update_data, update_type) => {
    if (token_string === null) { token_string = localStorage.getItem('token') }
    try {
      const { data } = await Axios.patch(
        `${API_URL}/logins`, 
        { 
          id,
          update_data,
          update_type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token_string
          }
        }
      );
      return data;
    } catch (error) {
      return {
        success: false,
        message: 'Sorry we could not update login details. Please try again',
      }
    }
  }
