import React, { useEffect, useState } from 'react';
import { login } from '../../actions/user-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from "../../utils/components/Loading"

const Index = () => {
  const userSignin = useSelector(state => state.userSignin);
  const { loading, userInfo, error } = userSignin;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const redirect = this.props.location.search ? this.props.location.search.split("=")[1] : 'dashboard';
  const redirect = 'dashboard'

  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');
  // eslint-disable-next-line
  const [action_loading, set_action_loading] = useState(false);

  const [email, set_email] = useState('');
  const [password, set_password] = useState('');

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const signin_user = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);

    try {
      await dispatch(login(email, password, redirect));
      // const response = await login(email, password, redirect, props);
      // // console.log('component response', response)
      // if (response.success) {

      //   set_success_message(response.message)
      //   setForm(3);

      // } else {
      //   set_error_message(response.message);
      // }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured signing up your account');
    }

    set_action_loading(false);


    return;
  }


  return (
    <>
      <section class="breadcrumbs">
        <div class="container">

          <div class="d-flex justify-content-between align-items-center">
            <h2></h2>
            <ol>
              <li></li>
              <li></li>
            </ol>
          </div>

        </div>
      </section>

      <main id="main mt-5">

        <div class="container">
          <div class="row">
            <div class="offset-md-4 col-md-4">
              <section class="inner-page">
                <div class="container">
                  <>
                    <div className="form">
                      <form onSubmit={signin_user} >
                        <div>
                          {error && <div class="col-12">
                            <div class="alert alert-danger" role="alert">
                              {error}
                            </div>
                          </div>}
                          {error_message !== '' ? (
                            <div class="alert alert-danger" role="alert">
                              {error_message}
                            </div>
                          ) : ('')}
                          {success_message !== '' ? (
                            <div class="alert alert-success" role="alert">
                              {success_message}
                            </div>
                          ) : ('')}
                        </div>

                        <div>
                          <input
                            type="text"
                            className="input-field form-control mt-3"
                            placeholder="Email or username"
                            onChange={(e) => set_email(e.target.value)}
                          />
                        </div>
                        <div>
                          <input
                            type="password"
                            className="input-field form-control mt-3"
                            placeholder="Password"
                            onChange={(e) => set_password(e.target.value)}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary mt-3"
                          disabled={loading} >
                          Sign In
                        </button>
                      </form>
                    </div>
                    <div className="links">
                      {/* <a
          onClick={() => {
            setForm(2);
          }}
        >
          Forgot Password
        </a> */}
                    </div>
                  </>
                </div>
              </section>
            </div>
          </div>
        </div>





      </main>
    </>
  );
};

export default Index;
