import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import jwt_decode from "jwt-decode";
import { Link } from 'react-router-dom';
import { logout } from './actions/user-actions';
import { useDispatch } from 'react-redux';


import Home from "./pages/general/Home";
import SearchVehicle from "./pages/general/SearchVehicle";
import Fines from "./pages/dashboard/Fines";
import Error404 from "./pages/errors/Error404";
import Auth from "./pages/authentication/Index";
import Dashboard from "./pages/dashboard/Dashboard";
import Surveillance from "./pages/dashboard/Surveillance";
import LoggedUsers from "./pages/dashboard/LoggedUsers";
import Users from "./pages/dashboard/Users";
import AddUser from "./pages/dashboard/AddUser";

import UnresolvedIssues from "./pages/dashboard/UnresolvedIssues";
import Threats from "./pages/dashboard/Threats";

import AuditTrail from "./pages/dashboard/AuditTrail";
import AttackTypes from "./pages/dashboard/AttackTypes";
import Motivations from "./pages/dashboard/Motivations";

import Audit from "./pages/dashboard/Audit";

import Assets from "./pages/dashboard/Assets";
import AddAsset from "./pages/dashboard/AddAsset";

import Bookings from "./pages/dashboard/Bookings";
import AddBooking from "./pages/dashboard/AddBooking";

import Paynow from "./pages/payment/Paynow";
import PaymentConfirmation from "./pages/payment/PaymentConfirmation";




function App() {

  // console.log('path', (useLocation()).pathname)
  const dispatch = useDispatch();


  const checked_if_logged_in = () => {
    const token_string = localStorage.getItem("token");
    try {
      var userObject = jwt_decode(token_string);
      return userObject;
    } catch (error) {
      return "";
    }
  };
  
  let role = ''

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  



  const logged_in_details = checked_if_logged_in();

  if (logged_in_details) {
    if ((checked_if_logged_in().roles).includes('admin')) {
      role = 'admin'
    }
    if ((checked_if_logged_in().roles).includes('hacker')) {
      role = 'hacker'
    }
    if ((checked_if_logged_in().roles).includes('erp')) {
      role = 'erp'
    }
  }

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  return (
    <>

      <header id="header" class="fixed-top  header-transparent ">
        <div class="container d-flex align-items-center justify-content-between">

          <div class="logo">
            <h1><a href="/dashboard">{process.env.REACT_APP_SYSTEM_NAME}</a></h1>
          </div>


          { role === 'admin' ? (
            <nav id="navbar" class="navbar">
              <ul> 
                <li><a class="nav-link scrollto" href="/dashboard">Dashboard</a></li>
                <li class="dropdown"><a href="#"><span>Configuration</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  {/* <li><a href="/add-users">add</a></li>
                  <li><a href="/users">view</a></li> */}
                  <li class="dropdown"><a href="#"><span>Users</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="/add-user">add users</a></li>
                      <li><a href="/users">view users</a></li>
                    </ul>
                  </li>
                  {/* <li class="dropdown"><a href="#"><span>Attack motivations</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="/add-motivation">add motivation</a></li>
                      <li><a href="/motivations">view motivation</a></li>
                    </ul>
                  </li>
                  <li class="dropdown"><a href="#"><span>Attack types</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="/add-user">add attack type</a></li>
                      <li><a href="/users">view attack types</a></li>
                    </ul>
                  </li>
                  <li class="dropdown"><a href="#"><span>Items at risk</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="/add-user">add new</a></li>
                      <li><a href="/users">view items at risk</a></li>
                    </ul>
                  </li> */}
                </ul>
              </li>

              <li class="dropdown"><a href="#"><span>Cyber Risk Analysis</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="/audit-trail">Audit Trail</a></li>
                  <li><a href="/unresolved-issues">Unresolved Issues</a></li>
                  
                  <li><a href="/threats">Threats</a></li>
                  <li><a href="/motivations">Motivations</a></li>
                  <li><a href="/attack-types">Attack Types</a></li>

                  <li><a href="/logged-users">Logged Users</a></li>
                </ul>
              </li>
                
                <li>
                  <form onSubmit={logoutUser} >
                          <button class="getstarted scrollto" type="submit">Logout</button>
                        </form>
                </li>
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
          ) : role === 'hacker' || role === 'erp' ? (
            <nav id="navbar" class="navbar">
            <ul> 
              <li><a class="nav-link scrollto" href="/dashboard">Dashboard</a></li>
             

            <li class="dropdown"><a href="#"><span>Assets</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/add-asset">Add Asset</a></li>
                <li><a href="/assets">Assets</a></li>
              </ul>
            </li>

            <li class="dropdown"><a href="#"><span>Bookings</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/add-booking">Add Booking</a></li>
                <li><a href="/bookings">Bookings</a></li>
              </ul>
            </li>

            {/* <li class="dropdown"><a href="#"><span>Finance</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/add-expense">Add Expense</a></li>
                <li><a href="/add-income">Add Income</a></li>
                <li><a href="/expenses">Income</a></li>
                <li><a href="/income">Income</a></li>
              </ul>
            </li> */}

            <li class="dropdown"><a href="#"><span>Users</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/add-user">Add User</a></li>
                <li><a href="/users">Users</a></li>
              </ul>
            </li>
              
              <li>
                <form onSubmit={logoutUser} >
                        <button class="getstarted scrollto" type="submit">Logout</button>
                      </form>
              </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
          ) : role === 'erp-staff' ? (
            <nav id="navbar" class="navbar">
              <ul> 
                <li><a class="nav-link scrollto" href="/dashboard">Dashboard</a></li>
               

              <li class="dropdown"><a href="#"><span>User</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="/add-user">Add User</a></li>
                  <li><a href="/users">Users</a></li>
                </ul>
              </li>
                
                <li>
                  <form onSubmit={logoutUser} >
                          <button class="getstarted scrollto" type="submit">Logout</button>
                        </form>
                </li>
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
          ) : (
            <nav id="navbar" class="navbar">
              <ul>
                <li><Link class="getstarted scrollto" to="/login">Admin Login</Link></li>
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>
          )}





        </div>

      </header>





      { role === 'admin' ? (
        <Routes>
          <Route
            path="/"
            exact={true}
            element={<Home />}
          />
          <Route path="/signin" element={<Auth />} />
          <Route
            path="/dashboard"
            element={<Dashboard user_profile={logged_in_details} />}
          />
          <Route
            path="/surveillance/:id"
            element={<Surveillance user_profile={logged_in_details} />}
          />
          <Route path="/audit-trail" element={<AuditTrail />} />
          <Route path="/logged-users" element={<LoggedUsers />} />
          <Route path="/unresolved-issues" element={<UnresolvedIssues />} />
          <Route path="/threats" element={<Threats/>} />
          <Route path="/add-user" element={<AddUser/>} />
          <Route path="/users" element={<Users/>} />
          <Route path="/add-asset" element={<AddAsset/>} />
          <Route path="/assets" element={<Assets/>} />
          <Route path="/attack-types" element={<AttackTypes />} />
          <Route path="/motivations" element={<Motivations />} />
          <Route path="/audit/:id" element={<Audit />} />
          {/* <Route
            path="/payment-success/:student"
            element={<SubscriptionSuccess user_profile={logged_in_details} />}
          />

          <Route
            path="/payment-failed"
            element={<SubscriptionFailed user_profile={logged_in_details} />}
          /> */}

          <Route path="*" element={<Error404 />} />
        </Routes>
      ) : role === 'hacker' || role === 'erp' ? (
        <Routes>
          <Route
            path="/"
            exact={true}
            element={<Home />}
          />
          <Route path="/signin" element={<Auth />} />
          <Route
            path="/dashboard"
            element={<Dashboard user_profile={logged_in_details} />}
          />
          <Route path="/audit-trail" element={<AuditTrail />} />
          <Route path="/logged-users" element={<LoggedUsers />} />
          <Route path="/unresolved-issues" element={<UnresolvedIssues />} />
          <Route path="/threats" element={<Threats/>} />
          {/* <Route path="/add-user" element={<AddUser/>} />
          <Route path="/users" element={<Users/>} /> */}

          <Route path="/add-user" element={<AddUser/>} />
          <Route path="/users" element={<Users/>} />
          
          <Route path="/add-asset" element={<AddAsset/>} />
          <Route path="/assets" element={<Assets/>} />

          <Route path="/add-booking" element={<AddBooking/>} />
          <Route path="/bookings" element={<Bookings/>} />
          {/* <Route
            path="/payment-success/:student"
            element={<SubscriptionSuccess user_profile={logged_in_details} />}
          />

          <Route
            path="/payment-failed"
            element={<SubscriptionFailed user_profile={logged_in_details} />}
          /> */}

          <Route path="*" element={<Error404 />} />
        </Routes>
      )  : (
        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/signin" element={<Auth />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/search" element={<SearchVehicle/>} />
          <Route path="/pay/:id" element={<Paynow />} />
          <Route path="/confirm_payment/:id" element={<PaymentConfirmation />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      )
    }

      <>
        <footer id="footer">
          <div class="container py-4">
            <div class="copyright">
              &copy; <strong><span>{process.env.REACT_APP_SYSTEM_NAME}</span></strong>. All Rights Reserved
            </div>
            <div class="credits">
              {process.env.REACT_APP_FOOTER_MESSAGE}
            </div>
          </div>
        </footer>
        <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
      </>
    </>
  );
}

export default App;
