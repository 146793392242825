import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';



import { logout } from '../../actions/user-actions';
import { get_ratio } from '../../actions/road-actions';
import { add_violation, get_violations, update_violation } from '../../actions/violation-actions';
import { get_logins } from '../../actions/login-actions';
import { get_audits } from '../../actions/audit-actions';


const Dashboard = (props) => {
  // console.log('user profile in dashboard', props.user_profile )

  const dispatch = useDispatch()

  const background_colours = ['red', 'blue', 'green']

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const [vehicle, set_vehicle] = useState('')

  const [adding_new_zone, set_adding_new_zone] = useState(false)
  const [action_loading, set_action_loading] = useState(false)
  const [page_loaded, set_page_loaded] = useState(false)

  const [error_message, set_error_message] = useState('')
  const [success_message, set_success_message] = useState('')

  const [name, set_name] = useState('')
  const [iot_device_unique_id, set_iot_device_unique_id] = useState('')
  const [irrigating_start_time, set_irrigating_start_time] = useState('')
  const [irrigating_end_time, set_irrigating_end_time] = useState('')
  const [irrigating_time_minutes, set_irrigating_time_minutes] = useState('')
  const [irrigating_days, set_irrigating_days] = useState('')
  const [min_moisture, set_min_moisture] = useState(0)
  const [max_moisture, set_max_moisture] = useState(200)
  const [min_pressure, set_min_pressure] = useState(0)
  const [max_pressure, set_max_pressure] = useState(200)
  const [min_power, set_min_power] = useState(0)
  const [max_power, set_max_power] = useState(5)
  const [max_proximity, set_max_proximity] = useState(200)
  const [moisture_units, set_moisture_units] = useState('KF')
  const [proximity_units, set_proximity_units] = useState('km')
  const [pressure_units, set_pressure_units] = useState('kPa')
  const [power_units, set_power_units] = useState('V')


  const [sunday_checked, set_sunday_checked] = useState(false)
  const [monday_checked, set_monday_checked] = useState(false)
  const [tuesday_checked, set_tuesday_checked] = useState(false)
  const [wednesday_checked, set_wednesday_checked] = useState(false)
  const [thursday_checked, set_thursday_checked] = useState(false)
  const [friday_checked, set_friday_checked] = useState(false)
  const [saturday_checked, set_saturday_checked] = useState(false)

  const [zones, set_zones] = useState([])
  const [view_settings, set_view_settings] = useState(false)

  const [unreviewed_actions, set_unreviewed_actions] = useState(0)
  const [identified_threats, set_identified_threats] = useState(0)
  const [resolved_threats, set_resolved_threats] = useState(0)
  const [logged_users, set_logged_users] = useState(0)

  const [top_motivation_count, set_top_motivation_count] = useState(0)
  const [top_attack_type_count, set_top_attack_type_count] = useState(0)
  const [top_threat_count, set_top_threat_count] = useState(0)

  const [top_motivation, set_top_motivation] = useState('')
  const [top_attack_type, set_top_attack_type] = useState('')
  const [top_threat, set_top_threat] = useState('')

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const add_new_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);

    let add_data = {
      vehicle,
    }

    try {
      const response = await add_violation(add_data);
      if (response.success) {
        set_success_message(response.message)

        set_vehicle('')
        await load_page_data()

      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured adding traffic violation');
    }

    set_action_loading(false);

    return;
  }


  const update_a_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);


    // let update_data = {

    // }

    // try {
    //   const response = await update_zone(zones[selected_zone]._id, update_data, 'basic_details');
    //   if (response.success) {
    //     set_success_message(response.message)

    //     set_name('')
    //     set_iot_device_unique_id('')
    //     await load_page_data()
    //     set_view_settings(false)

    //   } else {
    //     set_error_message(response.message);
    //   }
    // } catch (error) {
    //   console.log(`${error.message}`);
    //   set_error_message('An error occured adding new zone');
    // }

    set_action_loading(false);

    return;
  }

  const load_page_data = async () => {
    let get_admins
    try {
      get_admins = await get_logins('id');
      //   console.log(get_admins)
      if (get_admins.success) {
        const { data } = get_admins;
        
        let _logins = 0
        for (let i = 0; i < data.length; i++ ) {
          if (data[i].status === 'in') {
            _logins++
          }
        }

        set_logged_users(_logins)
        
      }
    } catch (error) {
      set_error_message(`An error occured fetching imposed traffic violations: ${error.message} `);
    }


    try {
      get_admins = await get_audits('id');
    //   console.log(get_admins)
      if (get_admins.success) {
        const { data } = get_admins;

        let _resolved_threats = 0
        let _identified_threats = 0
        let _unreviewed_threats = 0
        for (let i = 0; i < data.length; i++) {
          if (data[i].authenticated === 'No') {
            _identified_threats++

            if (data[i].status === 'Resolved') {
              _resolved_threats++
            } else {
              _unreviewed_threats++
            }
          }
        }

        set_resolved_threats(_resolved_threats)
        set_identified_threats(_identified_threats)
        set_unreviewed_actions(_unreviewed_threats)

        const types = [
          "Identity Theft",
          "Malware",
          "Denial-of-Service (DoS) Attacks",
          "Phishing",
          "Spoofing",
          "Identity-Based Attacks",
          "Code Injection Attacks",
          "Supply Chain Attacks",
          "Insider Threats",
          "DNS Tunneling",
          "IoT-Based Attacks",
          "Unknown",
        ]
        let _fines = []
        for (let j = 0; j < types.length; j++) {
            let t = types[j]
            let count = 0

            for (let i = 0; i < data.length; i++) {
                const f = data[i]
                if (f.authenticated) {
                     if (f.attack_type === t) {
                        count++
                     }
                }
            }

            _fines.push({
                t,
                count,
            })
        }
        let _top_type_count = 0
        let _top_type = ''
        for (let k = 0; k < _fines.length; k++) {
          if (_fines[k].count > _top_type_count) {
            _top_type = _fines[k].t
            _top_type_count = _fines[k].count
          }
        }
        set_top_attack_type(_top_type)
        set_top_attack_type_count(_top_type_count)
        
        const motivations = [
            "Financial Gain",
            "Espionage",
            "Hacktivism",
            "Sabotage and Disruption",
            "Personal Vendettas",
            "Ransom",
            "Political Discord",
            "Competitive Advantage/Stealing Trade Secrets",
            "Thrill-Seeking and Notoriety",
            "Unknown",
        ]
        _fines = []
        for (let j = 0; j < motivations.length; j++) {
            let t = motivations[j]
            let count = 0

            for (let i = 0; i < data.length; i++) {
                const f = data[i]
                if (f.authenticated) {
                     if (f.motivation === t) {
                        count++
                     }
                }
            }

            _fines.push({
                t,
                count,
            })
        }
        let _top_motivation_count = 0
        let _top_motivation = ''
        for (let k = 0; k < _fines.length; k++) {
          if (_fines[k].count > _top_motivation_count) {
            _top_motivation = _fines[k].t
            _top_motivation_count = _fines[k].count
          }
        }
        set_top_motivation(_top_motivation)
        set_top_motivation_count(_top_motivation_count)

        const modules = [
          "user",
          "finance",
          "bookings",
          "assets",
          "unknown",
        ]
      _fines = []
      for (let j = 0; j < modules.length; j++) {
          let t = modules[j]
          let count = 0

          for (let i = 0; i < data.length; i++) {
              const f = data[i]
              if (f.authenticated) {
                   if (f.module === t) {
                      count++
                   }
              }
          }

          _fines.push({
              t,
              count,
          })
      }
      let _top_module_count = 0
      let _top_module = ''
      for (let k = 0; k < _fines.length; k++) {
        if (_fines[k].count > _top_module_count) {
          _top_module = _fines[k].t
          _top_module_count = _fines[k].count
        }
      }
      set_top_threat(_top_module)
      set_top_threat_count(_top_module_count)

      }
    
    } catch (error) {
      set_error_message(`An error occured fetching imposed traffic violations: ${error.message} `);
    }

    return;

  }


  useEffect(() => {
    if (!page_loaded) {
      load_page_data()
      set_page_loaded(true)
    }
    setInterval(
      load_page_data,
      3000,
    )
    // eslint-disable-next-line
  }, []);

  return (
    <>



      <main id="main m-5">
        <div class="container my-5">
          <div class="row">
            <div class="offset-md-1 col-md-10">

              <div class="row">

                {error_message !== '' ? (
                  <div class="alert alert-danger" role="alert">
                    {error_message}
                  </div>
                ) : ('')}
                {success_message !== '' ? (
                  <div class="alert alert-success" role="alert">
                    {success_message}
                  </div>
                ) : ('')}
              </div>


              <div class="row mt-5">
                <h3>Welcome</h3>

                {/* the simulator comes here  */}
                {/* 
                1. controlling traffic through density based system 
                2. signal which side to open traffic to 
                3. auto identify vehicles numbers as vehicles is crossing 
                4. auto impose a fine to offending vehicles 
                 */}

                {/* here list the cameras that will be used  */}
                <div class="row">
                  <div class="col-md-6">
                    {/* <img src="assets/img/roads.jpg" class="img-fluid" alt="" /> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30367.81004358427!2d31.07393892012348!3d-17.93326139282256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931bd3f880051db%3A0xc805c2f3a0515763!2sRobert%20Gabriel%20Mugabe%20International%20Airport!5e0!3m2!1sen!2szw!4v1707498705399!5m2!1sen!2szw"
                      width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>

                  <div class="col-md-6 row">
                    <div class="col-md-3 alert alert-danger" role="alert">
                      <h3>{unreviewed_actions}</h3>
                      <br />
                      unreviewed actions
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 alert alert-primary" role="alert">
                      <h3>{identified_threats}</h3>
                      <br />
                      identified threats
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 alert alert-secondary" role="alert">
                      <h3>{resolved_threats}</h3>
                      <br />
                      resolved threats
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-5 alert alert-success" role="alert">
                      <h3>Top attack motivation</h3>
                      <br />
                      {top_motivation} ({top_motivation_count} cases)
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-5 alert alert-danger" role="alert">
                      <h3>Top Attack Type</h3>
                      <br />
                      {top_attack_type} ({top_attack_type_count} cases)
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-5 alert alert-primary" role="alert">
                      <h3>Top item at risk</h3>
                      <br />
                      {top_threat} ({top_threat_count})
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-5 alert alert-secondary" role="alert">
                      <h3>{logged_users}</h3>
                      <br />
                      logged users
                    </div>
                    <div class="col-md-1"></div>

                  </div>
                </div>

                {/* <div class="row mt-5 mb-5">
                  <div class="col-md-4">
                    <form onSubmit={add_new_violation}>
                    <div class="form-group">
                      <label>Vehicle number</label>
                      <input
                                                        type="text"
                                                        className="input-field form-control mt-3"
                                                        pattern="[A-Z]{3}[0-9]{4}"
                                                        placeholder="Eg. AFM8853"
                                                        onChange={(e) => set_vehicle(e.target.value)}
                                                    />
                    </div>

                    <div class="form-group">
                    <button type="submit" className="btn btn-primary mt-3"
                                                    disabled={action_loading} >
                                                    Add violation
                                                </button>
                    </div>
                    </form>
                  </div>
                 </div> */}

              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
