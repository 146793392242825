import React from "react";
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>
            <section id="hero" class="d-flex align-items-center">

                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1" data-aos="fade-up">
                            <div>
                                <h1>{process.env.REACT_APP_SYSTEM_NAME}</h1>
                                <h2>Administrators Panel</h2>
                                <Link to={`/login`} class="download-btn">
                                    Login to continue
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img" data-aos="fade-up">
                            <img src="assets/img/air-tower-control.png" class="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
};

export default Home;
