import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';



import { logout } from '../../actions/user-actions';
import { get_ratio } from '../../actions/road-actions';
import { add_user } from '../../actions/user-actions';

const Adduser = (props) => {
  // console.log('user profile in dashboard', props.user_profile )

  const dispatch = useDispatch()


  // TODO: aDD MORE COLORS 
  const background_colours = ['red', 'blue', 'green']

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const [vehicle, set_vehicle] = useState('')

  const [first_name, set_first_name] = useState('')
  const [last_name, set_last_name] = useState('')
  const [phone, set_phone] = useState('')
  const [email, set_email] = useState('')
  const [password, set_password] = useState('')
  const [roles, set_roles] = useState('')

  const [adding_new_zone, set_adding_new_zone] = useState(false)
  const [action_loading, set_action_loading] = useState(false)
  const [page_loaded, set_page_loaded] = useState(false)

  const [error_message, set_error_message] = useState('')
  const [success_message, set_success_message] = useState('')


  const [hamutyinei_to_lis, set_hamutyinei_to_lis] = useState(0)
  const [hamutyinei_to_mkoba, set_hamutyinei_to_mkoba] = useState(0)
  const [lg_to_ascot, set_lg_to_ascot] = useState(0)
  const [lg_to_cbd, set_lg_to_cbd] = useState(0)

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const add_new_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);

    if (roles === "") {
      set_error_message('User role cannot be null')
      set_action_loading(false)
      return
    }

    let add_data = {
      first_name,
      last_name,
      phone,
      email,
      password: "12341234",
      roles: [roles]
    }

    try {
      const response = await add_user(add_data);
      if (response.success) {
        set_success_message(response.message)

        set_first_name('')
        set_last_name('')
        set_phone('')
        set_email('')
        set_password('')
        set_roles('')
        await load_page_data()

      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured adding');
    }

    set_action_loading(false);

    return;
  }


  const update_a_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);


    // let update_data = {

    // }

    // try {
    //   const response = await update_zone(zones[selected_zone]._id, update_data, 'basic_details');
    //   if (response.success) {
    //     set_success_message(response.message)

    //     set_name('')
    //     set_iot_device_unique_id('')
    //     await load_page_data()
    //     set_view_settings(false)

    //   } else {
    //     set_error_message(response.message);
    //   }
    // } catch (error) {
    //   console.log(`${error.message}`);
    //   set_error_message('An error occured adding new zone');
    // }

    set_action_loading(false);

    return;
  }

  const load_page_data = async () => {
    console.log('reload')
    try {
      const response = await get_ratio();
      if (response.success) {
        set_hamutyinei_to_lis(response.hamutyinei_to_lis)
        set_hamutyinei_to_mkoba(response.hamutyinei_to_mkoba)
        set_lg_to_ascot(response.lg_to_ascot)
        set_lg_to_cbd(response.lg_to_cbd)
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured fetching zones');
    }

  }


  useEffect(() => {
    if (!page_loaded) {
      load_page_data()
      set_page_loaded(true)
    }
    setInterval(
      load_page_data,
      3000,
    )
    // eslint-disable-next-line
  }, []);

  return (
    <>



      <main id="main m-5">
        <div class="container my-5">
          <div class="row">
            <div class="offset-md-1 col-md-10">

              <div class="row">

                {error_message !== '' ? (
                  <div class="alert alert-danger" role="alert">
                    {error_message}
                  </div>
                ) : ('')}
                {success_message !== '' ? (
                  <div class="alert alert-success" role="alert">
                    {success_message}
                  </div>
                ) : ('')}
              </div>


              <div class="row mt-5">
                <h3>Add New User</h3>



                <div class="row mt-5 mb-5">
                  <div class="col-md-12">
                    <form onSubmit={add_new_violation} class="row">
                      <div class="col-md-6 form-group">
                        <label>First name</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_first_name(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label>Last name</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_last_name(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 mt-3 form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_email(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 mt-3 form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_phone(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-12 mt-3 form-group">
                        <label>Role</label>
                        <select
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_roles(e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value="admin">System Administrator</option>
                          <option value="erp">ERP Staff</option>
                          {/* <option value="hacker">Hacker</option> */}
                        </select>
                      </div>

                      {/* set_first_name('')
        set_last_name('')
        set_phone('')
        set_email('')
        set_password('')
        set_roles('') */}

                      <div class="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary mt-3"
                          disabled={action_loading} >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Adduser;
