import Axios from "axios";
import {
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
} from "../constants/userConstants";

import { API_URL, API_KEY } from "../config";
import jwt_decode from "jwt-decode";
let token_string = localStorage.getItem('token');



/**
 * Adds a developer account.
 * 
 * @param {Object} send_data - details of the person to add.
 */
export const add_developer = async (send_data) => {
  try {
    const { data } = await Axios.post(
      `${API_URL}/users`,
      send_data,
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not create your account. Please try again',
    }
  }
}

export const add_user = async (send_data) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    // console.log('37', API_URL, send_data, token_string)
    const { data } = await Axios.post(
      `http://127.0.0.1:4002/admins`,
      send_data,
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      },
    );
    // console.log('42', data)
    return data;
  } catch (error) {
    console.log('45', error.message);
    return {
      success: false,
      message: 'Sorry we could not create your account. Please try again',
    }
  }
}

/**
 * Verify OTP after registration
 * 
 * @param {String} otp - otp sent by user
 */
export const verify_otp = async (otp, username) => {
  if (username === null) { username = localStorage.getItem('username') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/users/verify-otp`,
      {
        otp,
        username,
      },
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not create your account. Please try again',
    }
  }
}

/**
 * Forgot password
 * 
 * @param {String} email - email to reset password
 */
export const forgot_password = async (email) => {
  try {
    const { data } = await Axios.post(
      `${API_URL}/users/forgot-password`,
      {
        email,
      },
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not reset password. Please try again',
    }
  }
}

/**
 * Verify OTP after registration
 * 
 * @param {String} otp - otp sent by user
 */
export const verify_reset_password_otp = async (otp, username) => {
  if (username === null) { username = localStorage.getItem('username') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/users/verify-reset-password-otp`,
      {
        otp,
        username,
      },
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not initiate resetting password. Please try again',
    }
  }
}

/**
 * Updates a password after an attempt to reset
 * 
 * @param {String} otp - otp sent by user
 * @param {String} username - username sent by user
 * @param {String} password - new password sent by user
 */
export const update_password = async (otp, username, password) => {
  if (username === null) { username = localStorage.getItem('username') }
  if (otp === null) { otp = localStorage.getItem('otp') }

  try {
    const { data } = await Axios.post(
      `${API_URL}/users/update-password`,
      {
        otp,
        username,
        password,
      },
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Sorry we could not initiate resetting password. Please try again',
    }
  }
}

/**
 * Sign in a user.
 * @param {String} email 
 * @param {String} password 
 * @param {*} props 
 * @param {String} redirect_link 
 */
export const login = (email, password, redirect_link) => async (dispatch) => {
  // let history = useHistory();
  // console.log(email, password, redirect_link)

  try {
    const { data } = await Axios.post(
      `${API_URL}/admins/login`,
      { email, password }
    );

    // console.log(data)

    if (data.success) {
      localStorage.setItem('token', data.token);
      // if (data.role === 'admin') {
      //   window.location.replace('/2fa');
      // } else {
      //   dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      //   window.location.replace(redirect_link);
      // }
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      window.location.replace(redirect_link);

      // history.push(redirect_link);

    } else {
      dispatch({ type: USER_SIGNIN_FAIL, payload: data.message });
    }

  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: 'An error occured loggin you in. Please try again' });
  }
}

export const login_after_account_verification = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  dispatch({ type: USER_SIGNIN_SUCCESS });
}

/**
 * Updates user details
 * @param {Object} update_data 
 * @param {String} update_key
 */
export const update_user = async (id, update_data, update_type) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.patch(
      `${API_URL}/users`,
      {
        id,
        update_data,
        update_type,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not update your account details. Please try again',
    }
  }
}

export const logout = () => async (dispatch) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.post(
      `http://127.0.0.1:4002/admins/logout`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      }
    );

    localStorage.removeItem('token');
    window.location.replace('/');
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'An error occured getting user profile',
    };
  }


}

export const get_user = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(
      `${API_URL}/users?id=${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + token_string,
        },
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'An error occured getting user profile',
    };
  }
}

export const check_logged_in = () => {
  let token_string = localStorage.getItem('token');
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    var user_object = jwt_decode(token_string);
    return user_object;
  } catch (error) {
    return '';
  }
}

export const verify_user = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.post(
      `${API_URL}/users/verify-by-admin`,
      {
        api_key: API_KEY,
        id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message)
    return {
      success: false,
      message: 'Sorry we could not verify account details. Please try again',
    }
  }
}

/**
 * Deletes a user details
 * @param {String} id
 */
export const delete_user = async (id) => {
  if (token_string === null) { token_string = localStorage.getItem('token') }
  try {
    const { data } = await Axios.delete(
      `${API_URL}/users`,
      {
        id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token_string
        }
      }
    );
    return data;
  } catch (error) {
    return {
      success: false,
      message: 'Sorry we could not delete your account details. Please try again',
    }
  }
}

export const get_users = async () => {
  if (token_string === null) { token_string = localStorage.getItem('token') }

  try {
    const { data } = await Axios.get(
      `http://127.0.0.1:4002/admins`, {
      headers: {
        Authorization: 'Bearer ' + token_string,
      },
    });
    return data;
  } catch (error) {
    console.log(error.message);
    return {
      success: false,
      message: 'Failed to retrieve user(s). Please reload page',
    }
  }
}