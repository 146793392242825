import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';



import { logout } from '../../actions/user-actions';
import { get_ratio } from '../../actions/road-actions';
import { add_asset } from '../../actions/asset-actions';

const AddAsset = (props) => {
  // console.log('user profile in dashboard', props.user_profile )

  const dispatch = useDispatch()


  // TODO: aDD MORE COLORS 
  const background_colours = ['red', 'blue', 'green']

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const [vehicle, set_vehicle] = useState('')

  const [name, set_name] = useState('')
  const [tag, set_tag] = useState('')
  const [serial_number, set_serial_number] = useState('')
  const [area, set_area] = useState('')
  const [brand, set_brand] = useState('')

  const [adding_new_zone, set_adding_new_zone] = useState(false)
  const [action_loading, set_action_loading] = useState(false)
  const [page_loaded, set_page_loaded] = useState(false)

  const [error_message, set_error_message] = useState('')
  const [success_message, set_success_message] = useState('')


  const [hamutyinei_to_lis, set_hamutyinei_to_lis] = useState(0)
  const [hamutyinei_to_mkoba, set_hamutyinei_to_mkoba] = useState(0)
  const [lg_to_ascot, set_lg_to_ascot] = useState(0)
  const [lg_to_cbd, set_lg_to_cbd] = useState(0)

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const add_new_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);

    if (area === "") {
      set_error_message('Area cannot be null')
      set_action_loading(false)
      return
    }

    let add_data = {
      name, area, tag, serial_number, brand, 
    }

    try {
      const response = await add_asset(add_data);
      if (response.success) {
        set_success_message(response.message)

        set_name('')
        set_tag('')
        set_serial_number('')
        set_area('')
        set_brand('')
        await load_page_data()

      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured adding');
    }

    set_action_loading(false);

    return;
  }


  const update_a_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);


    // let update_data = {

    // }

    // try {
    //   const response = await update_zone(zones[selected_zone]._id, update_data, 'basic_details');
    //   if (response.success) {
    //     set_success_message(response.message)

    //     set_name('')
    //     set_iot_device_unique_id('')
    //     await load_page_data()
    //     set_view_settings(false)

    //   } else {
    //     set_error_message(response.message);
    //   }
    // } catch (error) {
    //   console.log(`${error.message}`);
    //   set_error_message('An error occured adding new zone');
    // }

    set_action_loading(false);

    return;
  }

  const load_page_data = async () => {
    console.log('reload')
    try {
      const response = await get_ratio();
      if (response.success) {
        set_hamutyinei_to_lis(response.hamutyinei_to_lis)
        set_hamutyinei_to_mkoba(response.hamutyinei_to_mkoba)
        set_lg_to_ascot(response.lg_to_ascot)
        set_lg_to_cbd(response.lg_to_cbd)
      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured fetching zones');
    }

  }


  useEffect(() => {
    if (!page_loaded) {
      load_page_data()
      set_page_loaded(true)
    }
    setInterval(
      load_page_data,
      3000,
    )
    // eslint-disable-next-line
  }, []);

  return (
    <>



      <main id="main m-5">
        <div class="container my-5">
          <div class="row">
            <div class="offset-md-1 col-md-10">

              <div class="row">

                {error_message !== '' ? (
                  <div class="alert alert-danger" role="alert">
                    {error_message}
                  </div>
                ) : ('')}
                {success_message !== '' ? (
                  <div class="alert alert-success" role="alert">
                    {success_message}
                  </div>
                ) : ('')}
              </div>


              <div class="row mt-5">
                <h3>Add New Asset</h3>



                <div class="row mt-5 mb-5">
                  <div class="col-md-12">
                    <form onSubmit={add_new_violation} class="row">
                      <div class="col-md-6 form-group">
                        <label>Asset Name</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_name(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label>Asset Tag</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_tag(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 mt-3 form-group">
                        <label>Brand</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_brand(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-6 mt-3 form-group">
                        <label>Serial number</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_serial_number(e.target.value)
                          }
                        />
                      </div>

                      <div class="col-md-12 mt-3 form-group">
                        <label>Location</label>
                        <select
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_area(e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value="Arrivals Area">Arrivals Area</option>
                          <option value="Departures Area">Departures Area</option>
                          <option value="Lounge 1">Lounge 1</option>
                          <option value="Lounge 2">Lounge 2</option>
                          <option value="Lounge 3">Lounge 3</option>
                          <option value="Runway Area 1">Runway Area 1</option>
                          <option value="Runway Area 2">Runway Area 2</option>
                          <option value="Runway Area 3">Runway Area 3</option>
                          <option value="Car Park A">Car Park A</option>
                          <option value="Car Park B">Car Park B</option>
                        </select>
                      </div>

                      {/* set_first_name('')
        set_last_name('')
        set_phone('')
        set_email('')
        set_password('')
        set_roles('') */}

                      <div class="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary mt-3"
                          disabled={action_loading} >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddAsset;
