import Axios from "axios";

import { API_URL  } from "../config";
import { cos } from "@tensorflow/tfjs";
let token_string = localStorage.getItem('token');


export const add_violation = async (add_data) => {
    if (token_string === null) { token_string = localStorage.getItem('token_string') }
  
    try {
      const { data } = await Axios.post(
        `${API_URL}/violations`, 
        add_data, 
      );
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Sorry we could not add violation. Please try again',
      }
    }
  }
  

  export const add_payment = async (add_data) => {
    if (token_string === null) { token_string = localStorage.getItem('token_string') }
  
    try {
      const { data } = await Axios.post(
        `${API_URL}/violations/payment`, 
        add_data, 
      );
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Sorry we could not add payment. Please try again',
      }
    }
  }

  export const get_violations = async (id) => {
    try {
      const { data } = await Axios.get(
        `${API_URL}/violations?id=${id}`, {
        //   headers: {
        //     Authorization: 'Bearer ' + token_string,
        //   },
        });
        console.log(data)
      return data;
    } catch (error) {
      console.log(error.message);
      return {
        success: false,
        message: 'Failed to retrieve violation(s). Please reload page',
      }
    }
  }

  export const update_violation = async (id, update_data, update_type) => {
    if (token_string === null) { token_string = localStorage.getItem('token') }
    try {
      const { data } = await Axios.patch(
        `${API_URL}/violations`, 
        { 
          id,
          update_data,
          update_type,
        },
        // {
        //   headers: {
        //     Authorization: 'Bearer ' + token_string
        //   }
        // }
      );
      return data;
    } catch (error) {
      return {
        success: false,
        message: 'Sorry we could not update violation details. Please try again',
      }
    }
  }
