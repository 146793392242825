import React, { useState, useEffect } from "react";
import { useNavigate, useParams, redirect } from "react-router-dom";
import { add_payment } from '../../actions/violation-actions';

import { get_audits, update_audit } from '../../actions/audit-actions';

const Audit = (props) => {
  const params = useParams()
  // console.log(params.id)
  const navigate = useNavigate()

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);
  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');

  const [fines, set_fines] = useState('');

  const [recommendations, set_recommendations] = useState('')
  const [motivation, set_motivation] = useState('Unknown')
  const [attack_type, set_attack_type] = useState('Unknown')


  const id = params.id
  const _package = params.package

  const packages = {
    Gold: 15.99,
    Bronze: 10.99,
    Silver: 7.99
  }

  const load_page_data = async () => {
    let get_admins

    try {
      get_admins = await get_audits(id);
    //   console.log(get_admins)
      if (get_admins.success) {
        const { data } = get_admins;
        set_fines(data[0]);
      }

    } catch (error) {
      set_error_message(`An error occured fetching imposed traffic violations: ${error.message} `);
    }


    return;
  }


  const add_new_violation = async (e) => {
    e.preventDefault();

    set_success_message('');
    set_error_message('');
    set_action_loading(true);


    let update_data = {
      recommendations,
      attack_type,
      motivation,
      status: 'Resolved'
    }

    try {
      const response = await update_audit(
        id, 
        update_data, 
        'resolve_details'
      );
      if (response.success) {
        set_success_message('Success')

        set_recommendations('')
        await load_page_data()
        // set_view_settings(false)

      } else {
        set_error_message(response.message);
      }
    } catch (error) {
      console.log(`${error.message}`);
      set_error_message('An error occured adding new zone');
    }

    set_action_loading(false);

    return;
  }


  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main id="main m-5">
        <div class="container my-5">
          <div class="row">
            <div class="offset-md-1 col-md-10">

              <div class="row">

                {error_message !== '' ? (
                  <div class="alert alert-danger" role="alert">
                    {error_message}
                  </div>
                ) : ('')}
                {success_message !== '' ? (
                  <div class="alert alert-success" role="alert">
                    {success_message}
                  </div>
                ) : ('')}
              </div>


              <div class="row mt-5">
                <h3>Resolve an issue</h3>



                <div class="row mt-5 mb-5">
                  <div class="col-md-12">
                    <form onSubmit={add_new_violation} class="row">
                      
                      

                      <div class="col-md-6 mt-3 form-group">
                        <label>Motivation</label>
                        <select
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_motivation(e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value="Financial Gain">Financial Gain</option>
<option value="Espionage">Espionage</option>
<option value="Hacktivism">Hacktivism</option>
<option value="Sabotage and Disruption">Sabotage and Disruption</option>
<option value="Personal Vendettas">Personal Vendettas</option>
<option value="Ransom">Ransom</option>
<option value="Political Discord">Political Discord</option>
<option value="Competitive Advantage/Stealing Trade Secrets">Competitive Advantage/Stealing Trade Secrets</option>
<option value="Thrill-Seeking and Notoriety">Thrill-Seeking and Notoriety</option>
<option value="Unknown">Unknown</option>
                        </select>
                      </div>



                      <div class="col-md-6 mt-3 form-group">
                        <label>Attack type</label>
                        <select
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_attack_type(e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value="Identity Theft">Identity Theft</option>
                          <option value="Malware">Malware</option>
<option value="Denial-of-Service (DoS) Attacks">Denial-of-Service (DoS) Attacks</option>
<option value="Phishing">Phishing</option>
<option value="Spoofing">Spoofing</option>
<option value="Identity-Based Attacks">Identity-Based Attacks</option>
<option value="Code Injection Attacks">Code Injection Attacks</option>
<option value="Supply Chain Attacks">Supply Chain Attacks</option>
<option value="Insider Threats">Insider Threats</option>
<option value="DNS Tunneling">DNS Tunneling</option>
<option value="IoT-Based Attacks">IoT-Based Attacks</option>
<option value="Unknown">Unknown</option>
                        </select>
                      </div>

                      <div class="col-md-12 mt-3 form-group">
                        <label>Future recommendations</label>
                        <input
                          type="text"
                          className="input-field form-control mt-3"
                          // pattern=""
                          placeholder=""
                          onChange={
                            (e) => set_recommendations(e.target.value)
                          }
                        />
                      </div>

                      {/* set_first_name('')
        set_last_name('')
        set_recommendations('')
        set_email('')
        set_password('')
        set_roles('') */}

                      <div class="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary mt-3"
                          disabled={action_loading} >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Audit;
