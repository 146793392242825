import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { update_violation } from '../../actions/violation-actions';

const PaymentConfirmation = (props) => {
  const params = useParams()
  const navigate = useNavigate()

  const [page_loaded, set_page_loaded] = useState(false);
  const [action_loading, set_action_loading] = useState(false);
  const [error_message, set_error_message] = useState('');
  const [success_message, set_success_message] = useState('');


  const id = params.id

//   const packages = {
//     Gold: 15.99,
//     Bronze: 10.99,
//     Silver: 7.99
//   }

  const load_page_data = async () => {
    let get_all_users;

    let update_data = {
      status: 'Paid',
    }

    let update_type = 'finish_paynow'

    try {
      get_all_users = await update_violation(id, update_data, update_type)
      if (get_all_users.success === false) {
        set_error_message(get_all_users.message);
      }
      else {
        set_success_message('Payment successfully received.');

      }
    } catch (error) {
      set_error_message(`An error occured initiating payment: ${error.message} `);
    }

    return;
  }

  useEffect(() => {
    if (page_loaded === false) {
      load_page_data();
      set_page_loaded(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mt-5" style={{ margin: 'auto'}}>
            <p class="alert alert-success">
              Your payment has been processed successfully.</p>

              <a href="/search">Back to search</a>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentConfirmation;
